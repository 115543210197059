import React, { useState, useEffect } from "react";
import axios from "axios";

interface WithdrawItem {
  uid: string; 
  date: string;
  wallet: string;
  amount: number; 
  coin: string; 
  name: string;
  amount_usdt: number; 
  status: string;
}

const Withdraw = () => {
  const [withdrawData, setWithdrawData] = useState<WithdrawItem[]>([]);
  const [searchTerm, setSearchTerm] = useState(''); 

  useEffect(() => {
    fetchWithdrawData();
  }, []);

  const fetchWithdrawData = async () => {
    try {
      const response = await axios.get('https://metananobackend.metablocktechnologies.org/getWithdrawData');
      const data = response.data;

      if (Array.isArray(data)) {
        const reversedData = data.reverse();
        setWithdrawData(reversedData);
      } else {
        console.error('Unexpected response structure:', data);
        setWithdrawData([]);
      }
    } catch (error) {
      console.error('Failed to fetch withdrawal data:', error);
      setWithdrawData([]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = async (uid: string, newStatus: string) => {
    try {
      await axios.post('https://metananobackend.metablocktechnologies.org/updateWithdrawStatus', {
        uid,
        status: newStatus,
      });
      fetchWithdrawData();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  const filteredWithdrawData = searchTerm
    ? withdrawData.filter((withdraw) => 
        String(withdraw.uid).includes(searchTerm) || 
        withdraw.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : withdrawData;

  return (
    <div className='ml-0 lg:ml-[256px]'>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or UID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full"
        />
      </div>
      <div className="h-[690px] overflow-scroll">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UID</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount (Original)</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Wallet Address</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Coin</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount (USDT)</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Update Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredWithdrawData.map((withdraw, index) => (
              <tr key={withdraw.uid}>
                <td className="py-3 px-4 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.uid}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.name}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.date}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.amount}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.wallet}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.coin}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.amount_usdt.toFixed(2)}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.status}</td>
                <td className="py-3 px-4 border-b border-gray-200">
                  <select
                    value={withdraw.status}
                    onChange={(e) => handleStatusChange(withdraw.uid, e.target.value)}
                    className="p-1 border rounded w-full"
                  >
                    <option value="In Queue">In Queue</option>
                    <option value="Processing">Processing</option>
                    <option value="Done">Done</option>
                    <option value="Wager Not Completed">Wager Not Completed</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Withdraw;
